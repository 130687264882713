<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="content">
            <p>可兑换太阳数: 0.00</p>
            <div class="input-content">
                <span>兑换太阳：</span>
                <input type="text" placeholder="请输入兑换数量">
                <img src="../../assets/images/cross.png" alt="">
            </div>
            <div class="submitBtn">
                <button>确定兑换</button>
            </div>
        </div>
        <div class="desc">
            <p>【重要】：</p>
            <p>1、太阳不能退款、不能提取，只能用于平台内部使用</p>
            <p>收益一旦兑换成太阳，不能退回，是不可逆操作</p>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .container{
        width: 100%;
        min-height: 100%;
        position: absolute;
        top: 0;
        background-color: #eee;
        .content{
            width: 100%;
            height: 160px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            p{
                padding: 0;
                margin: 0;
                font-size: 16px;
                padding-top: 10px;
                /* height: 60px;
                line-height: 60px; */
            }
            .input-content{
                width: 100%;
                height: 44px;
                background-color: #fff;
                position: relative;
                line-height: 40px;
                padding-left: 10px;
                span{
                    font-size: 16px;
                    display: inline-block;
                    /* padding: 0 10px; */
                    vertical-align: middle;
                }input{
                    /* height: 100%; */
                    border: none;
                    font-size: 16px;
                    vertical-align: middle;
                }
                img{
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                }
            }
            .submitBtn{
                width: 100%;
                text-align: center;
                margin-top: 20px;
                    button{
                        border: none;
                        width: 60%;
                        height: 36px;
                        background-color: #d25555;
                        border-radius: 6px;
                        font-size: 14px;
                        color: #fff;
                        box-shadow: 0px 2px 2px 0px rgba(210, 85, 85,0.5);
                    }
                }
            }
            .desc{
            font-size: 12px;
            height: 140px;
            color: #d25555;
            width: 94%;
            margin: 10px auto;
            p{
                padding: 0;
                margin: 0;
            }
        }
    }
</style>